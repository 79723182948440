import React, { useState } from 'react';
import '../styles/formStyles.css';

export const Form = ({ fetchData }) => {
  const [name, setName] = useState(typeof window !== 'undefined' ? localStorage.getItem('name') || '' : '');
  const [pts, setPts] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const done = await fetch('https://sbfkjstekk.execute-api.us-west-2.amazonaws.com/serverless_lambda_stage/add/point', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, pts: parseInt(pts) }),
    });


    if (typeof window !== 'undefined') {
      localStorage.setItem('name', name);
    }

    setName('');
    setPts('');
    fetchData(true);


    setName(typeof window !== 'undefined' ? localStorage.getItem('name') || '' : '');
  };

  return (
    <div className="form-wrapper">
      <form className="form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">NAME:</label>
        </div>
        <div>
          <input type="text" id="name" onChange={(e) => setName(e.target.value.toUpperCase())} value={name}></input>
        </div>
        <div>
          <label htmlFor="pts">STORY PTS:</label>
        </div>
        <div>
          <input type="number" id="pts" onChange={(e) => setPts(e.target.value)} value={pts}></input>
        </div>
        <button className={name.length && pts.length ? 'yep' : 'nope'} type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};


