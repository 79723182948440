import React from 'react';
import '../styles/tableStyles.css';

export const Table = ({ ptsObj }) => {
  if (!ptsObj) return null;

  const ptsList = Object.keys(ptsObj);

  return (
    <div className="wrapper">
      {ptsList.map((theKey, i) => (
        <div key={theKey} className={`row ${(i === ptsList.length - 1) ? " remove-padding" : ""}`}>
          <div className="column">
            <div className="teal-column">
              <p>{theKey}</p>
            </div>
            <div className="blue-column">{<p>{ptsObj[theKey]}</p>}</div>
          </div>
        </div>
      ))}

      <div className="total">
        <div className="column">
          <div className="teal-column dark-teal">
            <p>TOTAL</p>
          </div>
          <div className="blue-column dark-blue">{<p>{ptsList.reduce((pv, cv) => pv + ptsObj[cv], 0)}</p>}</div>
        </div>
      </div>
    </div>
  );
};
