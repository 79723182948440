import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { Home } from '../components/Home';

const pageStyles = {
  color: '#232129',
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Home />
    </main>
  );
};

export default IndexPage;

const isProd = process.env.NODE_ENV === 'production';

export const Head: HeadFC = () => (
  <>
    <title>{isProd ? 'How Many Pts' : 'Local: How Many Pts'}</title>
    <meta name="icon" data-href="src/assets/favicon-32x32.png" />
  </>
);

if (!isProd) {
  document.documentElement.style.backgroundColor = 'gray';
}
