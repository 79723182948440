import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form } from './Form';
import { Table } from './Table';

let websock: WebSocket;

export const Home = () => {
  const [ptsObj, setPtsObj] = useState(null);

  const fetchData = async (updateSockets: boolean) => {
    if (updateSockets) {
      websock.send(JSON.stringify({ action: 'sendmessage', message: 'ptsEntered' }));
    }
    const rawPts = await fetch('https://sbfkjstekk.execute-api.us-west-2.amazonaws.com/serverless_lambda_stage/fetch/points');
    const pts = await rawPts.json();

    delete pts['dateKey'];

    setPtsObj(pts);
  };

  const connectToWebsocket = () => {
    websock = new WebSocket('wss://ko0z2d285e.execute-api.us-west-2.amazonaws.com/primary');
    websock.onmessage = (event) => {
      fetchData(false);
    };
  };

  useEffect(() => {
    fetchData(false).catch(console.error);
  }, []);

  useEffect(() => {
    connectToWebsocket();
  }, []);

  return (
    <>
      <Form fetchData={fetchData} />
      <Table ptsObj={ptsObj} />
    </>
  );
};
